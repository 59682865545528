import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Header.css";

import { WindowContext } from "../../context/WindowContext.js";
import Texts from "../../assets/texts/texts.json"

export default function Header({ isHeroPage, showMobileMenuFromHero }) {

  const { pathname } = useLocation();

  const { windowSize, menuIsVisible, setMenuIsVisible } = useContext(WindowContext);

  const [responsiveMenuOn, setResponsiveMenuOn] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const activateResponsiveMenu = () => {
    setResponsiveMenuOn(prev => !prev);
    setDropdown(false);
  }

  useEffect(() => {
    if (showMobileMenuFromHero) {
      setResponsiveMenuOn(true);
    }
    if (!isHeroPage) {
      setMenuIsVisible(true);
    }
  }, [showMobileMenuFromHero]);

  return (
    <>
      <div className={`hamburger-container ${responsiveMenuOn ? "close-hamburger" : ""}`} onClick={activateResponsiveMenu}>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
      </div>
      <header className={!responsiveMenuOn && windowSize[1] < 1051 ? "responsiveMenuOff" : ""}>
        <nav className={menuIsVisible || windowSize[1] < 1051 ? "menu" : "hidden-menu"}>
          <ul className={`${!isHeroPage ? "" : "transparent"} ${menuIsVisible || windowSize[1] < 1051 ? "visible" : "hidden-list"}`}>
            <li>
              <Link to="/sobre-pixel16" className={`${Texts[pathname]?.section === "about" ? "active-link" : ""}`} onClick={activateResponsiveMenu}>ESTA WEB</Link>
            </li>
            <li className="dropdown" onMouseEnter={() => setDropdown("concepts")} onMouseLeave={() => setDropdown(false)}>
              <Link className={`${Texts[pathname]?.section === "concepts" ? "active-link" : ""}`}>CONCEPTOS</Link>
              {dropdown === "concepts" && <div className="dropdown-content">
                <Link to="/como-ajustar-fotos-sin-conocimientos" className={`${pathname.includes("/como-ajustar-fotos-sin-conocimientos") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>AJUSTAR FOTOS SIN RECURSOS NI CONOCIMIENTOS</Link>
                <Link to="/del-betun-de-judea-al-pixel-y-photoshop" className={`${pathname.includes("del-betun-de-judea-al-pixel-y-photoshop") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>DEL BETÚN DE JUDEA AL PIXEL</Link>
                <Link to="/formacion-autodidacta" className={`${pathname.includes("formacion-autodidacta") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>SOBRE LA FORMACIÓN AUTODIDACTA</Link>
                <Link to="/todo-revelado" className={`${pathname.includes("todo-revelado") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>TODO LO QUE PUEDES VER HA SIDO REVELADO</Link>
                <Link to="/blanco-negro-escala-grises" className={`${pathname.includes("blanco-negro-escala-grises") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>¿BLANCO Y NEGRO O ESCALA DE GRISES?</Link>
                <Link to="/revelador-y-editor" className={`${pathname.includes("revelador-y-editor") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>REVELADOR Y EDITOR SON COSAS DISTINTAS</Link>
                <Link to="/como-funciona-el-sensor-de-la-camara-digital" className={`${pathname.includes("como-funciona-el-sensor-de-la-camara-digital") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EL SENSOR. PÉRDIDA Y RECUPERACIÓN DE PÍXELES</Link>
                <Link to="/que-es-el-rango-dinamico" className={`${pathname.includes("que-es-el-rango-dinamico") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>RANGO DINÁMICO</Link>
                <Link to="/linealidad-en-la-captura" className={`${pathname.includes("linealidad-en-la-captura") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>LINEALIDAD EN LA CAPTURA</Link>
                <Link to="/gamma-digital" className={`${pathname.includes("gamma-digital") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>LA GAMMA</Link>
                <Link to="/miedo-a-sobreexponer" className={`${pathname.includes("miedo-a-sobreexponer") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>MIEDO A SOBREEXPONER</Link>
                <Link to="/formatos-de-archivo" className={`${pathname.includes("formatos-de-archivo") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>FORMATOS DE ARCHIVO</Link>
                <Link to="/sobre-jpg-y-raw" className={`${pathname.includes("sobre-jpg-y-raw") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>COMENTARIOS SOBRE JPG Y RAW</Link>
                <Link to="/que-es-la-resolucion-de-la-imagen" className={`${pathname.includes("que-es-la-resolucion-de-la-imagen") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>RESOLUCIÓN</Link>
                <Link to="/iluminacion-fotografica-estacion-de-trabajo" className={`${pathname.includes("iluminacion-fotografica-estacion-de-trabajo") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>ILUMINACIÓN DE LA ESTACIÓN DE TRABAJO</Link>
                <Link to="/tono-saturacion-de-color-y-brillo" className={`${pathname.includes("tono-saturacion-de-color-y-brillo") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>TONO SATURACIÓN Y BRILLO (AVANZADO)</Link>
                <Link to="/copia" className={`${pathname.includes("copia") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EVALUACIÓN VISUAL DE LA COPIA</Link>
                <Link to="/que-es-el-ruido-digital" className={`${pathname.includes("que-es-el-ruido-digital") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EL RUIDO</Link>
                <Link to="/que-es-la-sintesis-aditiva-del-color" className={`${pathname.includes("que-es-la-sintesis-aditiva-del-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>SÍNTESIS ADITIVA</Link>
              </div>}
            </li>
            <li className="dropdown" onMouseEnter={() => setDropdown("color")} onMouseLeave={() => setDropdown(false)}>
              <Link className={`${Texts[pathname]?.section === "color" ? "active-link" : ""}`}>GESTIÓN DE COLOR</Link>
              {dropdown === "color" && <div className="dropdown-content">
                <Link to="/que-es-la-gestion-del-color" className={`${pathname.includes("que-es-la-gestion-del-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>QUÉ ES Y QUÉ NO ES</Link>
                <Link to="/mapa-de-color" className={`${pathname.includes("mapa-de-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>MAPA DE COLOR</Link>
                <Link to="/los-modos-de-color" className={`${pathname.includes("los-modos-de-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>MODOS DE COLOR</Link>
                <Link to="/espacios-de-color" className={`${pathname.includes("espacios-de-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>ESPACIOS DE COLOR</Link>
                <Link to="/profundidad-de-color" className={`${pathname.includes("profundidad-de-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>PROFUNDIDAD DE COLOR</Link>
                <Link to="/ejemplos" className={`${pathname.includes("ejemplos") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EJEMPLOS CONFUSOS</Link>
                <Link to="/degradacion-imagen" className={`${pathname.includes("degradacion-imagen") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>DEGRADACIÓN DE LA IMAGEN</Link>
                <Link to="/que-es-el-banding" className={`${pathname.includes("que-es-el-banding") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EL BANDING</Link>
                <Link to="/edicion-no-destructiva" className={`${pathname.includes("edicion-no-destructiva") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EDICIÓN NO DESTRUCTIVA</Link>
                <Link to="/perfiles-de-color" className={`${pathname.includes("perfiles-de-color") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>PERFILES DE COLOR</Link>
              </div>}
            </li>
            <li className="dropdown" onMouseEnter={() => setDropdown("photoshop")} onMouseLeave={() => setDropdown(false)}>
              <Link className={`${Texts[pathname]?.section === "photoshop" ? "active-link" : ""}`}>PHOTOSHOP</Link>
              {dropdown === "photoshop" && <div className="dropdown-content">
                <Link to="/capas-en-photoshop" className={`${pathname.includes("capas-en-photoshop") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>CONCEPTO DE CAPAS</Link>
                <div>EN CONSTRUCCIÓN</div>
                <div>EN CONSTRUCCIÓN</div>
              </div>}
            </li>
            <li className="dropdown" onMouseEnter={() => setDropdown("raw")} onMouseLeave={() => setDropdown(false)}>
              <Link className={`${Texts[pathname]?.section === "raw" ? "active-link" : ""}`}>REVELADO RAW</Link>
              {dropdown === "raw" && <div className="dropdown-content">
                <Link to="/el-enfoque" className={`${pathname.includes("el-enfoque") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>EL ENFOQUE. CONCEPTOS</Link>
                <div>EN CONSTRUCCIÓN</div>
                <div>EN CONSTRUCCIÓN</div>
              </div>}
            </li>
            <li className="dropdown" onMouseEnter={() => setDropdown("mezclum")} onMouseLeave={() => setDropdown(false)}>
              <Link className={`${Texts[pathname]?.section === "mezclum" ? "active-link" : ""}`}>MEZCLUM</Link>
              { dropdown === "mezclum" && <div className="dropdown-content">
                <Link to="/iluminacion" className={`${pathname.includes("iluminacion") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>ILUMINACIÓN</Link>
                <Link to="/que-son-las-camaras-de-gran-formato" className={`${pathname.includes("que-son-las-camaras-de-gran-formato") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>GRAN FORMATO</Link>
                <Link to="/formato-medio" className={`${pathname.includes("formato-medio") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>FORMATO MEDIO</Link>
                <Link to="/formacion-solidaria" className={`${pathname.includes("formacion-solidaria") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>FORMACIÓN SOLIDARIA</Link>
                <Link to="/concurso" className={`${pathname.includes("concurso") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>CERTAMEN FOTOGRÁFICO PIXEL16</Link>
                <Link to="/reparacion-fotos-antiguas" className={`${pathname.includes("reparacion-fotos-antiguas") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>REPARACIÓN SOLIDARIA DE FOTOS ANTIGUAS ESTROPEADAS</Link>
                <Link to="/diseno-de-logotipo-y-marca" className={`${pathname.includes("diseno-de-logotipo-y-marca") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>DISEÑO SOLIDARIO DE LOGOTIPO Y MARCA</Link>
                <Link to="/grupo-fotografico" className={`${pathname.includes("grupo-fotografico") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>GRUPO FOTOGRÁFICO</Link>
                <Link to="/opiniones" className={`${pathname.includes("opiniones") ? "active-sub-link" : ""}`} onClick={activateResponsiveMenu}>OPINIONES</Link>
              </div>}
            </li>
          </ul>
        </nav>
      </header>
    </>
  )

}
